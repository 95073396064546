import {
  SEND_CONTACT_FORM_START,
  SEND_CONTACT_FORM_FAILURE,
  SEND_CONTACT_FORM_SUCCESS,

} from '../actionTypes';

const initial_state = {
  loading: false
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case SEND_CONTACT_FORM_START:
      return { ...state, loading: true };
    case SEND_CONTACT_FORM_SUCCESS:
      return { ...state, loading: false };
    case SEND_CONTACT_FORM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
}
