import {
  SYNC_REQUESTS_END,
  SYNC_REQUESTS_START,
  SYNC_REQUESTS_FAILURE,
  SYNC_REQUESTS_SUCCESS,
  SYNC_REQUESTS_TRANSFORM, VALIDATE_USER_START, VALIDATE_USER_FAILURE, VALIDATE_USER_SUCCESS,
} from '../actionTypes';

const initial_state = {
  requests: [],
  pending: [],
  error: '',
  loading: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case SYNC_REQUESTS_START:
      return { ...state, loading: true };
    case SYNC_REQUESTS_TRANSFORM:
      return { ...state, loading: true };
    case SYNC_REQUESTS_SUCCESS:
      return { ...state, loading: false, requests: action.payload.requests, pending: action.payload.pending };
    case SYNC_REQUESTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SYNC_REQUESTS_END:
      return { ...state, loading: false};

    case VALIDATE_USER_START:
      return { ...state };
    case VALIDATE_USER_SUCCESS:
      return { ...state };
    case VALIDATE_USER_FAILURE:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
}
