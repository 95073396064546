import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import {
  UPDATE_USER_START,
  GET_REQUEST_START,
} from '../actionTypes';
import rsf from 'redux/rsf';
import {
  updateUserSuccess,
  updateUserFailure,
  getRequestFailure,
  getRequestSuccess,
} from 'redux/actions/user.action';

function* updateUser({ payload, overwritePhotos, overwriteQuestions }) {
  try {
    const userID = yield select(({ Auth }) => Auth.user.id);
    if (payload.questions && payload.questions.length > 0) {
      if (!overwriteQuestions) {
        payload.questions = firebase.firestore.FieldValue.arrayUnion(...payload.questions);
      }
    } else {
      delete payload.questions
    }
    if (payload.photos && payload.photos.length > 0) {
      if (!overwritePhotos) {
        payload.photos = firebase.firestore.FieldValue.arrayUnion(...payload.photos);
      }
    } else {
      if (!overwritePhotos) {
        delete payload.photos
      }
    }
    yield call(
      rsf.firestore.updateDocument,
      `users/${userID}`,
      payload,
    )
    yield put(updateUserSuccess());
  } catch (err) {
    yield put(updateUserFailure(err));
  }
}

function* getRequest({ payload }) {
  try {
    const result = yield call(
      rsf.firestore.getDocument,
      `requests/${payload.id}`,
    )
    if (result.exists) {
      const request = result.data();

      const userDoc = yield call(rsf.firestore.getDocument, request.user);

      if (userDoc.exists) {
        const user = userDoc.data();
        request.user = user;
      }

      const churchDoc = yield call(rsf.firestore.getDocument, request.user.church);

      if (churchDoc.exists) {
        const church = churchDoc.data();
        request.user.church = church;
      }

      yield put(getRequestSuccess(request));
    } else {
      // const request = result.data()
      yield put(getRequestFailure(result));
    }
  } catch (err) {
    yield put(getRequestFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(UPDATE_USER_START, updateUser),
    takeEvery(GET_REQUEST_START, getRequest),
  ]);
}
