import {
  SUBSCRIPTION_REQUEST_START,
  SUBSCRIPTION_REQUEST_FAILURE,
  SUBSCRIPTION_REQUEST_SUCCESS,
  CHANGE_PAYMENT_METHOD_START,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_FAILURE,
  PROMO_CODE_SUBSCRIBE_START,
} from '../actionTypes';

export function subscribe(payload) {
  return { type: SUBSCRIPTION_REQUEST_START, payload }
}

export function subscribeSuccess(payload) {
  return { type: SUBSCRIPTION_REQUEST_SUCCESS, payload }
}

export function subscribeFailure(payload) {
  return { type: SUBSCRIPTION_REQUEST_FAILURE, payload }
}

export function changePaymentMethod(payload) {
  return { type: CHANGE_PAYMENT_METHOD_START, payload }
}

export function changePaymentMethodSuccess(payload) {
  return { type: CHANGE_PAYMENT_METHOD_SUCCESS, payload }
}

export function changePaymentMethodFailure(payload) {
  return { type: CHANGE_PAYMENT_METHOD_FAILURE, payload }
}

export function promoCodeSubscribe(payload) {
  return { type: PROMO_CODE_SUBSCRIBE_START, payload }
}
