import { createStore, applyMiddleware, compose } from "redux"
import reduxSaga from 'redux-saga';
import { createLogger } from 'redux-logger'

import rootSaga from 'redux/sagas';
import reducers from '../reducers/rootReducer';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    return undefined
  }
}

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
})

const sagaMiddleware = reduxSaga()

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(reducers, compose(
  applyMiddleware(sagaMiddleware, logger),

  //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
    return f;
  }
));

store.runSaga = sagaMiddleware.run(rootSaga);

// eslint-disable-next-line
const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export { store }
