import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
} from '../actionTypes';

export const uploadFile = ({ ref, file }) => ({
  type: UPLOAD_FILE_START,
  ref,
  file,
})

export const uploadFileSuccess = (file) => ({
  type: UPLOAD_FILE_SUCCESS,
  file,
})

export const uploadFileFailure = (err, file) => ({
  type: UPLOAD_FILE_FAILURE,
  err,
  file,
})
