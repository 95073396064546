import {
  put,
  call,
  all,
  fork,
  take,
  cancel,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  getFriendsFailure,
  getFriendsSuccess,
  getChannelSuccess,
  getChannelFailure,
  sendMessageFailure,
  sendMessageSuccess,
  syncChannelsProcess,
  readMessagesSuccess,
  readMessagesFailure,
  getFriends as getFriendsAction,
  setCurrentChannelFailure,
  setCurrentChannelSuccess,
  stopSyncChannel,
  getChannel,
} from '../actions/chat.action';
import {
  SYNC_CHANNELS_START,
  SYNC_CHANNELS_SUCCESS,
  LOGOUT_SUCCESS,
  GET_CHANNEL_START,
  STOP_SYNC_CHANNEL,
  SEND_MESSAGE_START,
  SYNC_CHANNELS_PROCESS,
  GET_CHANNEL_SUCCESS,
  SET_CURRENT_CHANNEL_START,
} from '../actionTypes';
import firebase from 'firebase/app';
import rsf, { db } from 'redux/rsf';

function transformChannels(results) {
  const channels = {};
  results.forEach((doc) => {
    channels[doc.id] = { data: doc.data(), id: doc.id };
  });
  return channels;
}

function* getFriends() {
  try {
    const channels = yield select(({ Chat }) => Chat.channels);
    const user = yield select(({ Auth }) => Auth.user);
    const friendsID = Object.entries(channels).map(([channelID, channel]) =>
      channel.data.users.find((friendID) => friendID !== user.id)
    );
    const result = yield call(
      rsf.firestore.getCollection,
      db
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), 'in', friendsID)
    );
    const friends = [];
    result.forEach((doc) => friends.push(doc.data()));

    friends.forEach((friend) => {
      const channel = Object.entries(channels).find(([channelID, channel]) =>
        channel.data.users.find((id) => id === friend.id)
      );
      if (channel[0]) {
        channels[channel[0]].friend = friend;
      }
    });
    yield put(getFriendsSuccess(friends, channels));
  } catch(err) {
    yield put(getFriendsFailure(err));
  }
}

function* channelsWatcher() {
  while (true) {
    yield take(SYNC_CHANNELS_START);
    const userID = yield select(({ Auth }) => Auth.user.id);

    let task = yield fork(
      rsf.firestore.syncCollection,
      db
      .collection('channels')
      .where('users', 'array-contains', userID)
      .orderBy('lastMessageDate', 'desc'),
      {
        successActionCreator: syncChannelsProcess,
        transform: transformChannels,
      }
    );

    yield take(LOGOUT_SUCCESS);
    yield cancel(task);
  }
}

function intoChunks(list, howMany) {
  const result = [];
  const input = [...list];
  while (input[0]) result.push(input.splice(0, howMany));
  return result;
}

function* syncChannelsProcessSaga({ payload: channels }) {
  yield put(getFriendsAction());
  const user = yield select(({ Auth }) => Auth.user);
  let friendsID = Object.entries(channels).map(([channelID, channel]) => {
    return channel.data.users.find((friendID) => friendID !== user.id);
  });

  let messages = Object.entries(channels).map(([channelID, channel]) => {
    const messagesRef = db
    .collection(`channels/${channelID}/threads`)
    .where('viewed', '==', false)
    .where('recipientID', '==', user.id)
    .limit(20);

    return call(rsf.firestore.getCollection, messagesRef);
  });
  messages = yield all(messages);
  Object.entries(channels).forEach(([channelID, channel], i) => {
    channel.unreadMessages = messages[i].size;
  });
  if (!friendsID) return;

  friendsID = intoChunks(friendsID, 10);

  const requests = friendsID.map((ids) =>
    call(
      rsf.firestore.getCollection,
      db
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
    )
  );
  let result = yield all(requests);

  const friends = [];
  result.forEach((query) => query.forEach((doc) => friends.push(doc.data())));

  friends.forEach((friend) => {
    const channel = Object.entries(channels).find(([channelID, channel]) =>
      channel.data.users.find((id) => id === friend.id)
    );
    if (channel[0]) {
      channels[channel[0]].friend = friend;
    }
  });

  yield put(getFriendsSuccess(friends, channels));
}

function* channelWatcher() {
  while (true) {
    const action = yield take(GET_CHANNEL_START);
    const currentChannel = yield select(({ Chat }) => Chat.currentChannel);

    let task = yield fork(
      rsf.firestore.syncCollection,
      db.collection(`channels/${action.payload}/threads`).orderBy('created'),
      {
        successActionCreator: (threads) => getChannelSuccess({ threads, currentChannel }),
        failureActionCreator: getChannelFailure,
        transform: (results) =>
          results.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      },
    );

    yield take([LOGOUT_SUCCESS, STOP_SYNC_CHANNEL]);
    yield cancel(task);
  }
}

function* readMessages({ payload: { currentChannel } }) {
  try {
    const user = yield select(({ Auth }) => Auth.user);
    const channels = yield select(({ Chat }) => Chat.channels);

    const messagesRef = db
    .collection(`channels/${currentChannel}/threads`)
    .where('viewed', '==', false)
    .where('recipientID', '==', user.id);

    const messages = yield call(rsf.firestore.getCollection, messagesRef);
    messages.forEach((message) => {
      message.ref.update({ viewed: true });
    });

    channels[currentChannel] = { ...channels[currentChannel], unreadMessages: 0 };

    yield put(readMessagesSuccess(channels));
  } catch(err) {
    yield put(readMessagesFailure(err));
  }
}

function* setCurrentChannel({ payload }) {
  try {
    const currentChannel = yield select(({ Chat }) => Chat.currentChannel);
    if (currentChannel !== payload) {
      yield put(setCurrentChannelSuccess(payload));
      yield put(stopSyncChannel());
      yield put(getChannel(payload));
    }
  } catch(err) {
    yield put(setCurrentChannelFailure(err));
  }
}

function* sendMessage({ payload }) {
  try {
    const { message, channel } = payload;
    message.created = message.created || firebase.firestore.FieldValue.serverTimestamp();

    yield call(
      rsf.firestore.addDocument,
      `channels/${channel.id}/threads/`,
      message
    );

    yield call(rsf.firestore.updateDocument, `channels/${channel.id}`, {
      lastMessage: message.content,
      lastMessageDate: message.created,
    });
    yield put(sendMessageSuccess(message, channel));
  } catch(err) {
    yield put(sendMessageFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    fork(channelsWatcher),
    fork(channelWatcher),
    takeLatest(GET_CHANNEL_SUCCESS, readMessages),
    takeLatest(SYNC_CHANNELS_SUCCESS, getFriends),
    takeLatest(SET_CURRENT_CHANNEL_START, setCurrentChannel),
    takeEvery(SEND_MESSAGE_START, sendMessage),
    takeEvery(SYNC_CHANNELS_PROCESS, syncChannelsProcessSaga),
  ]);
}
