import {
  put,
  all,
  call,
  race,
  take,
  fork,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import {
  syncChannels,
  setCurrentChannel,
} from 'redux/actions/chat.action';
import {
  NEXT_USER_START,
  FIRST_USER_START,
  DISCOVER_START,
  DISCOVER_SUCCESS,
  DISCOVER_FAILURE,
  LIKE_START,
  DISLIKE_START,
} from '../actionTypes';
import {
  likeSuccess,
  likeFailure,
  dislikeSuccess,
  dislikeFailure,
  discover as discoverAction,
  discoverSuccess,
  discoverFailure,
  nextUserSuccess,
  nextUserFailure,
  firstUser as firstUserAction,
  firstUserSuccess,
  firstUserFailure,
} from 'redux/actions/discover.action';
import rsf, { db } from 'redux/rsf';
import { sendMessage } from 'redux/actions/chat.action';
import moment from 'moment';
import axios from 'axios';

const genders = {
  Man: 'Woman',
  Woman: 'Man',
};

async function getData(object) {
  const defaultArrayKeys = ['church', 'leader', 'state', 'country'];
  const newObject = object.data();
  for (const key of defaultArrayKeys) {
    if (!newObject[key]) continue;
    const newValue = await newObject[key].get();
    newObject[key] = newValue.data();
  }
  return newObject;
}

function age_of(u) {
  return moment().diff(moment(u.birthday.toDate()), 'years');
}

// Is Discovered user's age within my chosen range (min and max)?
function withinMyAgeRange(their_age, my_min, my_max) {
  // if (max >= 38) return age >= min;  // [cw] Why 38?
  return their_age >= my_min && their_age <= my_max;
}

// Is my age within Discovered user's chosen range (min and max)?
function withinTheirAgeRange(my_age, them) {
  let their_min_age = my_age - 7; // Defaults
  let their_max_age = my_age + 7;

  if (!them.discovery) {
    their_min_age = age_of(them) - 7;
    their_max_age = age_of(them) + 7;
  } else if (them.discovery.ageRange) {
    their_min_age = them.discovery.ageRange[0];
    their_max_age = them.discovery.ageRange[1];
  }
  return my_age >= their_min_age && my_age <= their_max_age;
}

function chunkForDiscover(friendsIds) {
  if (!friendsIds || !friendsIds.size) return [];
  const cloneFriendsIds = [...friendsIds];
  const chunkFriendsId = [];
  while (cloneFriendsIds.length) {
    chunkFriendsId.push(cloneFriendsIds.splice(0, 10));
  }
  return chunkFriendsId;
}

function* discover(data = {}) {
  try {
    const currentUsers = yield select(({ Discover }) => Discover.users);
    if (currentUsers?.length > 1) {
      return yield put(discoverSuccess({ users: currentUsers }));
    }
    const userId = yield select(({ Auth }) => Auth.user.id);
    const url = `${process.env.REACT_APP_DTHS_API_URL}/v1/discovery`;
    const authToken = yield select(({ Auth }) => Auth.authInfo.stsTokenManager.accessToken)
    const options = { headers: { Authorization: `Bearer ${authToken}` } }
    try {
      const { data: { docs, hasNextPage } } = yield call(axios.get, url, options)

      const usersSnap = yield call(
        rsf.firestore.getCollection,
        db.collection('users').where('id', 'in', docs.map(doc => doc.originalId)));

      const users = yield all(usersSnap.docs.map(doc => getData(doc)));

      for (const [i, discoverUser] of users.entries()) {
        const userLikesQuery = db
          .collection('likes')
          .where('user', '==', db.doc(`users/${userId}`))
          .where('target', '==', db.doc(`users/${discoverUser.id}`));

        const userLikes = yield call(rsf.firestore.getCollection, userLikesQuery);

        users[i] = {
          ...discoverUser,
          likes: userLikes.docs.map((userLike) => ({
            ...userLike.data(),
            id: userLike.id,
          })),
        };
      }

      yield put(discoverSuccess({ users: [...(currentUsers || []), ...users] }));
    } catch (err) {
      yield put(discoverFailure(err));
    }
  } catch (err) {
    yield put(discoverFailure(err));
  }
}

function* nextUser() {
  try {
    const currentUser = yield select(({ Discover }) => Discover.currentUser);
    let users = yield select(({ Discover }) => Discover.users);

    if (currentUser && currentUser.id !== users[users.length - 1].id) {
      const currentIndex = users.findIndex(
        (user) => user.id === currentUser.id,
      );
      const next = users[currentIndex + 1];

      const newUsers = users.filter((user) => currentUser.id !== user.id);
      yield put(nextUserSuccess({ currentUser: next, users: newUsers }));
      yield put(discoverAction());
    } else {
      yield put(discoverAction());
      const result = yield race({
        success: take(DISCOVER_SUCCESS),
        failure: take(DISCOVER_FAILURE),
      });
      if (result.failure) {
        return yield put(nextUserFailure(result.failure.payload));
      }

      users = yield select(({ Discover }) => Discover.users);
      if (users) {
        const currentDiscoverID = users[0].id;

        yield put(nextUserSuccess({ currentUser: users[0], users }));
        yield put(firstUserAction({ newFirstUser: true }));
      } else {
        yield put(nextUserSuccess({ currentUser: null, users }));
      }
    }
  } catch (err) {
    yield put(nextUserFailure(err));
  }
}

function* firstUser({ payload = {} }) {
  try {
    const currentUser = yield select(({ Discover }) => Discover.currentUser);
    const { newFirstUser } = payload;

    if (newFirstUser) {
      yield put(firstUserSuccess({ firstUser: currentUser }));
    }
  } catch (err) {
    yield put(firstUserFailure(err));
  }
}

function* like({ payload = {} }) {
  try {
    const userTarget = yield select(({ Discover }) => Discover.currentUser);
    const user = yield select(({ Auth }) => Auth.user);
    const { targetType = 'Profile', message: messageContent = '', url = '' } = payload;
    const likeBuild = {
      targetUser: `${userTarget.firstName} ${userTarget.lastName}`,
      target: db.doc(`users/${userTarget.id}`),
      user: db.doc(`users/${user.id}`),
      targetType,
    };
    const like = yield call(rsf.firestore.addDocument, 'likes', likeBuild);
    const likes = [...userTarget.likes, { ...likeBuild, id: like.id }];
    const messageCreateDate = new Date();

    const channel = yield call(rsf.firestore.addDocument, 'channels', {
      creator_id: user.id,
      lastMessage: messageContent,
      lastMessageDate: messageCreateDate,
      users: [user.id, userTarget.id],
      leader: false,
      support: false,
    });

    const message = {
      url: url,
      viewed: false,
      senderID: user.id,
      content: messageContent,
      created: messageCreateDate,
      recipientID: userTarget.id,
      senderLastName: user.lastName || null,
      senderFirstName: user.firstName || null,
      recipientLastName: userTarget.lastName || null,
      recipientFirstName: userTarget.firstName || null,
      senderProfilePictureURL: user.profilePicture.fullURL || null,
      recipientProfilePictureURL: userTarget.profilePicture?.fullURL || null,
    };

    yield put(likeSuccess(likes));
    yield put(sendMessage(message, channel));
    yield put(setCurrentChannel(channel.id));
  } catch (err) {
    yield put(likeFailure(err));
  }
}

function* dislike({ payload = {} }) {
  try {
    const userTarget = yield select(({ Discover }) => Discover.currentUser);
    const { id } = payload;
    let likeRef = db.collection('likes').doc(id);

    yield call(rsf.firestore.deleteDocument, likeRef);

    const likes = userTarget.likes.filter((like) => id !== like.id);

    yield put(dislikeSuccess(likes));
  } catch (err) {
    yield put(dislikeFailure(err));
  }
}

export default function* discoverSaga() {
  yield all([
    takeLeading(NEXT_USER_START, nextUser),
    takeLeading(FIRST_USER_START, firstUser),
    takeLeading(DISCOVER_START, discover),
    takeEvery(DISLIKE_START, dislike),
    takeEvery(LIKE_START, like),
  ]);
}
