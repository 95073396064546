import {
  DISCOVER_START,
  DISCOVER_SUCCESS,
  DISCOVER_FAILURE,
  NEXT_USER_START,
  NEXT_USER_SUCCESS,
  NEXT_USER_FAILURE,
  FIRST_USER_START,
  FIRST_USER_SUCCESS,
  FIRST_USER_FAILURE,
  LIKE_START,
  LIKE_SUCCESS,
  LIKE_FAILURE,
  DISLIKE_START,
  DISLIKE_SUCCESS,
  DISLIKE_FAILURE,
  RESTART_DISCOVERY,
} from 'redux/actionTypes';

const initial_state = {
  users: [],
  loading: true,
  currentUser: null,
  firstUser: null,
  firstUserLoading: false,
  nextUserLoading: true,
  likeLoading: false,
  likeError: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case DISCOVER_START:
      return { ...state, loading: true };
    case DISCOVER_SUCCESS:
      return { ...state, loading: false, users: action.payload.users };
    case DISCOVER_FAILURE:
      return { ...state, loading: false };

    case NEXT_USER_START:
      return { ...state, nextUserLoading: true };
    case NEXT_USER_SUCCESS:
      return {
        ...state,
        nextUserLoading: false,
        currentUser: action.payload.currentUser,
        users: action.payload.users,
      };
    case NEXT_USER_FAILURE:
      return { ...state, nextUserLoading: false };

    case FIRST_USER_START:
      return { ...state, firstUserLoading: true };
    case FIRST_USER_SUCCESS:
      return { ...state, firstUserLoading: false, firstUser: action.payload.firstUser };
    case FIRST_USER_FAILURE:
      return { ...state, firstUserLoading: false };

    case LIKE_START:
      return { ...state, likeLoading: true };
    case LIKE_SUCCESS:
      return {
        ...state,
        likeLoading: false,
        currentUser: { ...state.currentUser, likes: action.payload },
      };
    case LIKE_FAILURE:
      return { ...state, likeLoading: false, likeError: action.payload };

    case DISLIKE_START:
      return { ...state, likeLoading: true };
    case DISLIKE_SUCCESS:
      return {
        ...state,
        likeLoading: false,
        currentUser: { ...state.currentUser, likes: action.payload },
      };
    case DISLIKE_FAILURE:
      return { ...state, likeLoading: false, likeError: action.payload };
    case RESTART_DISCOVERY:
      return { ...state, currentUser: null, firstUser: null, users: [], loading: true };

    default:
      return { ...state };
  }
};
