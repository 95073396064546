import {
  all,
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import rsf, { db } from '../rsf';
import { showToast } from '../actions/toast.action';
import { SEND_CONTACT_FORM_START } from '../actionTypes';
import { sendContactFailure, sendContactSuccess } from '../actions/contact.action';

function* sendContactForm({ contactForm }) {
  try {
    const country = db.doc(`countries/${contactForm.country}`);
    const contact = { ...contactForm, country };
    yield call(rsf.firestore.addDocument, '/contact/', contact);
    yield put(showToast({ type: 'success', message: 'Message has been sent' }));
    yield put(sendContactSuccess());
  } catch(error) {
    yield put(sendContactFailure(error));
    yield put(showToast({ type: 'error', message: error.message }));
  }
}

export default function* notificationSaga() {
  yield all([
    takeLatest(SEND_CONTACT_FORM_START, sendContactForm),
  ]);
}
