import { combineReducers } from 'redux';
import Auth from './auth.reducer';
import Chat from './chat.reducer';
import Church from './church.reducer';
import Leader from './leader.reducer';
import Country from './country.reducer';
import Storage from './storage.reducer';
import Contact from './contact.reducer';
import Request from './request.reducer';
import Discover from './discover.reducer';
import Question from './question.reducer';
import Customizer from './customizer.reducer';
import Subscription from './subscription.reducer';
import Notification from './notification.reducer';
import Geo from './geo.reducer';

const appReducer = combineReducers({
  Auth,
  Chat,
  Church,
  Leader,
  Country,
  Storage,
  Contact,
  Request,
  Question,
  Discover,
  Customizer,
  Subscription,
  Notification,
  Geo,
});

const rootReducer = (state, action) => {
  // Reset redux state on logout
  state = action.type === 'LOGOUT_SUCCESS' ? undefined : state;

  return appReducer(state, action);
};

export default rootReducer;
