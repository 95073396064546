import {
  SYNC_NOTIFICATION_START,
  SYNC_NOTIFICATION_FAILURE,
  SYNC_NOTIFICATION_SUCCESS,
  SYNC_NOTIFICATION_TRANSFORM,
  READ_NOTIFICATIONS_START,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
} from '../actionTypes';

export function syncNotification() {
  return { type: SYNC_NOTIFICATION_START };
}

export function syncNotificationSuccess(payload) {
  return { type: SYNC_NOTIFICATION_SUCCESS, payload };
}

export function syncNotificationTransform(payload) {
  return { type: SYNC_NOTIFICATION_TRANSFORM, payload };
}

export function syncNotificationFailure(payload) {
  return { type: SYNC_NOTIFICATION_FAILURE, payload };
}

export function readNotifications() {
  return { type: READ_NOTIFICATIONS_START };
}

export function readNotificationsSuccess() {
  return { type: READ_NOTIFICATIONS_SUCCESS};
}

export function readNotificationsFailure() {
  return { type: READ_NOTIFICATIONS_FAILURE };
}
