import { all } from 'redux-saga/effects';
import authSaga from './auth.saga';
import userSaga from './user.saga';
import chatSaga from './chat.saga';
import toastSaga from './toast.saga';
import churchSaga from './church.saga';
import leaderSaga from './leader.saga';
import countrySaga from './country.saga';
import storageSaga from './storage.saga';
import contactSaga from './contact.saga';
import requestSaga from './request.saga';
import discoverSaga from './discover.saga';
import questionSaga from './question.saga';
import subscriptionSaga from './subscription.saga';
import notificationSaga from './notification.saga';
import geoSaga from './geo.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    chatSaga(),
    toastSaga(),
    churchSaga(),
    leaderSaga(),
    countrySaga(),
    storageSaga(),
    contactSaga(),
    requestSaga(),
    discoverSaga(),
    questionSaga(),
    subscriptionSaga(),
    notificationSaga(),
    geoSaga(),
  ]);
}
