import {
  all,
  put,
  fork,
  call,
  take,
  cancel,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  LOGOUT_SUCCESS,
  SYNC_REQUESTS_END,
  SYNC_REQUESTS_START,
  SYNC_REQUESTS_TRANSFORM,
  VALIDATE_USER_START,
} from '../actionTypes';
import {
  syncRequestsFailure,
  syncRequestsSuccess,
  syncRequestsTransform,
  validateUserFailure,
  validateUserSuccess,
} from '../actions/request.action';
import rsf, { db } from '../rsf';

function* validateUser({ payload }) {
  try {
    const userId = payload.userId;
    const status = payload.status;
    const requestId = payload.requestId;

    yield call(
      rsf.firestore.updateDocument,
      `users/${userId}`,
      {
        validated: status,
      },
    );

    yield call(
      rsf.firestore.updateDocument,
      `requests/${requestId}`,
      {
        status: status ? 'active' : 'denied',
      },
    );

    yield put(validateUserSuccess({}));
  } catch(error) {
    yield put(validateUserFailure(error));
  }
}

function* transformRequests({ payload }) {
  try {
    const usersResult = yield all(
      payload.map((request) => call(rsf.firestore.getDocument, request.user)),
    );
    const requests = payload.filter((request, index) => usersResult[index].exists).map((
      request,
      index,
    ) => ({ ...request, user: usersResult[index].data() }));
    const pending = requests.filter((request) => request.status === 'pending');
    const completed = requests.filter((request) => request.status !== 'pending');
    yield put(syncRequestsSuccess({ requests: completed, pending }));
  } catch(error) {
    yield put(syncRequestsFailure(error));
  }
}

function* requestsWatcher() {
  let task;
  while (true) {
    yield take(SYNC_REQUESTS_START);
    const userID = yield select(({ Auth }) => Auth.user.id);
    const userRef = db.doc(`users/${userID}`);
    const collectionRef = db
    .collection('requests')
    .where('leader', '==', userRef);

    task = yield fork(
      rsf.firestore.syncCollection,
      collectionRef,
      {
        successActionCreator: syncRequestsTransform,
        failureActionCreator: syncRequestsFailure,
        transform: (results) =>
          results.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      },
    );

    yield take([LOGOUT_SUCCESS, SYNC_REQUESTS_END]);
    if (task) yield cancel(task);
  }
}

export default function* requestSaga() {
  yield all([
    takeLatest(SYNC_REQUESTS_TRANSFORM, transformRequests),
    takeLatest(VALIDATE_USER_START, validateUser),
    fork(requestsWatcher),
  ]);
}
