import { all, call, put, takeLatest } from 'redux-saga/effects';
import rsf, { db } from 'redux/rsf';
import {
  GET_CHURCHES_START,
} from '../actionTypes';
import {
  getChurchesSuccess,
  getChurchesFailure,
} from 'redux/actions/church.action';

function* getChurches({ payload }) {
  try {
    let query = db.collection('churches').where('active', '==', true);
    if (payload.country) {
      const countryRef = db.doc(`countries/${payload.country}`);
      query = query.where('country', '==', countryRef);
      if (payload.state) {
        const stateRef = db.doc(`countries/${payload.country}/states/${payload.state}`);
        query = query.where('state', '==', stateRef);
      }
    }
    const result = yield call(rsf.firestore.getCollection, query);
    const churches = result.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    yield put(getChurchesSuccess(churches));
  } catch (err) {
    yield put(getChurchesFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(GET_CHURCHES_START, getChurches),
  ]);
}
