

import {
  GET_GEOLOCATION_START,
  GET_GEOLOCATION_SUCCESS,
  GET_GEOLOCATION_FAILURE,
} from '../actionTypes';

const initial_state = {
  countryName: '',
  CountryCode: '',
  loading: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_GEOLOCATION_START:
      return { ...state, loading: true }
    case GET_GEOLOCATION_SUCCESS:
      return { ...state, ...action.geo, loading: false }
    case GET_GEOLOCATION_FAILURE:
      return { ...state, loading: false }

    default: return { ...state };
  }
}
