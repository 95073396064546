import {
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_REQUEST_START,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
} from '../actionTypes'

export const updateUser = (data, overwritePhotos, overwriteQuestions) => ({
  type: UPDATE_USER_START,
  payload: data,
  overwritePhotos,
  overwriteQuestions,
})
export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})
export const updateUserFailure = (err) => ({
  type: UPDATE_USER_FAILURE,
  payload: err,
})

export const getUser = (id) => ({
  type: GET_USER_START,
  payload: { id },
})
export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: user,
})
export const getUserFailure = (err) => ({
  type: GET_USER_FAILURE,
  payload: err,
})

export const getRequest = (id) => ({
  type: GET_REQUEST_START,
  payload: { id },
})
export const getRequestSuccess = (request) => ({
  type: GET_REQUEST_SUCCESS,
  payload: request,
})
export const getRequestFailure = (err) => ({
  type: GET_REQUEST_FAILURE,
  payload: err,
})
