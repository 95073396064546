import {
  SUBSCRIPTION_REQUEST_START,
  SUBSCRIPTION_REQUEST_FAILURE,
  SUBSCRIPTION_REQUEST_SUCCESS,
  PROMO_CODE_SUBSCRIBE_START,
} from '../actionTypes';

const initial_state = {
  success: false,
  loading: false,
  err: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case SUBSCRIPTION_REQUEST_START:
    case PROMO_CODE_SUBSCRIBE_START:
      return { ...state, loading: true, success: false }
    case SUBSCRIPTION_REQUEST_FAILURE:
      return { ...state, loading: false, success: true }
    case SUBSCRIPTION_REQUEST_SUCCESS:
      return { ...state, loading: false, success: false }

    default: return { ...state };
  }
}
