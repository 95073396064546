import { all, takeLeading, call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_COUNTRIES_START,
  GET_STATES_START,
} from '../actionTypes';
import rsf, { db } from 'redux/rsf';
import {
  getCountriesSuccess,
  getCountriesFailure,
  getStatesSuccess,
  getStatesFailure,
} from 'redux/actions/country.action';

function* getCountries() {
  try {
    const result = yield call(
      rsf.firestore.getCollection,
      db.collection('countries').orderBy('name')
    );
    const countries = result.docs.map(doc => doc.data());
    yield put(getCountriesSuccess(countries));
  } catch (err) {
    yield put(getCountriesFailure(err));
  }
}

function* getStates({ payload }) {
  try {
    const { countryID } = payload;
    const result = yield call(
      rsf.firestore.getCollection,
      db.collection(`countries/${countryID}/states`).orderBy('name')
    );
    const states = result.docs.map(doc => ({...doc.data(), id: doc.id}));
    yield put(getStatesSuccess(states));
  } catch (err) {
    yield put(getStatesFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLeading(GET_COUNTRIES_START, getCountries),
    takeLatest(GET_STATES_START, getStates),
  ]);
}
