import React from "react"
import classnames from "classnames"
import { Button, Col, Row } from "reactstrap"
import loginImg from 'assets/img/logo/transparent-logo.png';
import themeConfig from "../configs/themeConfig"
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth.action";

const FullPageLayout = ({ children, ...rest }) => {
  const logged = useSelector(({ Auth }) => Auth.logged);
  const dispatch = useDispatch();

  const onLogout = () => dispatch(logout());

  return (
    <div
      className={classnames(
        "full-layout wrapper bg-full-screen-image blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark
        }
      )}
    >
      <div className="container pt-1">
        <Row className="justify-content-between">
          <Col xs={3}>
            <div style={{
              margin: '0 auto',
              width: '100%',
              textAlign: 'left',
            }}>
              <div style={{
                verticalAlign: 'middle',
                display: 'block',
                width: '100%',
                height: 'auto',
                margin: '0px 0 0px 0',
              }}>
                <a href="https://static.dtheartandsoul.com/">
                  <img src={loginImg} height="40px" width="40px" alt="" />
                </a>
              </div>
            </div>
          </Col>
          {
            logged &&
            <Col xs={3} className="text-right">
              <Button color="secondary" onClick={onLogout}>Logout</Button>
            </Col>
          }
        </Row>
      </div>
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100">
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
