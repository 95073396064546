import { toast } from 'react-toastify';
import { takeEvery, all } from 'redux-saga/effects';
import { SHOW_TOAST } from '../actionTypes';

function showToast({ payload }) {
  toast[payload.type](payload.message);
}

export default function* authSaga() {
  yield all([
    takeEvery(SHOW_TOAST, showToast),
  ]);
}
