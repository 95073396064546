import {
  SYNC_REQUESTS_END,
  SYNC_REQUESTS_START,
  SYNC_REQUESTS_FAILURE,
  SYNC_REQUESTS_SUCCESS,
  SYNC_REQUESTS_TRANSFORM, VALIDATE_USER_START, VALIDATE_USER_SUCCESS, VALIDATE_USER_FAILURE,
} from '../actionTypes';

export function syncRequests() {
  return { type: SYNC_REQUESTS_START };
}

export function syncRequestsSuccess(payload) {
  return { type: SYNC_REQUESTS_SUCCESS, payload };
}

export function syncRequestsTransform(payload) {
  return { type: SYNC_REQUESTS_TRANSFORM, payload };
}

export function syncRequestsFailure(payload) {
  return { type: SYNC_REQUESTS_FAILURE, payload };
}

export function syncRequestsEnd() {
  return { type: SYNC_REQUESTS_END };
}

export function validateUser(payload) {
  return { type: VALIDATE_USER_START, payload };
}

export function validateUserSuccess(payload) {
  return { type: VALIDATE_USER_SUCCESS, payload };
}

export function validateUserFailure(payload) {
  return { type: VALIDATE_USER_FAILURE, payload };
}
