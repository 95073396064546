import {
  GET_LEADERS_FROM_CHURCH_START,
  GET_LEADERS_FROM_CHURCH_SUCCESS,
  GET_LEADERS_FROM_CHURCH_FAILURE,
  CHOOSE_LEADER_START,
  CHOOSE_LEADER_SUCCESS,
  CHOOSE_LEADER_FAILURE,
  USER_REQUEST_LEADER_START,
  USER_REQUEST_LEADER_SUCCESS,
  USER_REQUEST_LEADER_FAILURE,
  LEADER_REQUEST_ADMIN_START,
  LEADER_REQUEST_ADMIN_SUCCESS,
  LEADER_REQUEST_ADMIN_FAILURE,
  GET_REQUESTS_START,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAILURE,
  REPLY_USER_REQUEST_START,
  REPLY_USER_REQUEST_SUCCESS,
  REPLY_USER_REQUEST_FAILURE,
  GET_LEADERS_START,
  GET_LEADERS_SUCCESS,
  GET_LEADERS_FAILURE,
  GET_LEADER_START,
  GET_LEADER_SUCCESS,
  GET_LEADER_FAILURE,
  SET_CURRENT_LEADER,
  SAVE_LEADER_START,
  SAVE_LEADER_SUCCESS,
  SAVE_LEADER_FAILURE,
} from '../actionTypes';

const initial_state = {
  all: [],
  loading: true,
  settingLeader: false,
  error: null,
  requests: [],
  loadingRequests: true,
  requestsError: null,
  current: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_LEADERS_FROM_CHURCH_START:
      return { ...state, loading: true }
    case GET_LEADERS_FROM_CHURCH_SUCCESS:
      return { ...state, loading: false, all: action.payload.leaders }
    case GET_LEADERS_FROM_CHURCH_FAILURE:
      return { ...state, loading: false }
    
    case GET_LEADERS_START:
      return { ...state, loading: true }
    case GET_LEADERS_SUCCESS:
      return { ...state, loading: false, all: action.payload.leaders }
    case GET_LEADERS_FAILURE:
      return { ...state, loading: false }
    
    case GET_LEADER_START:
      return { ...state, loading: true }
    case GET_LEADER_SUCCESS:
      return { ...state, loading: false, current: action.payload }
    case GET_LEADER_FAILURE:
      return { ...state, loading: false }
    
    case SET_CURRENT_LEADER:
      return { ...state, current: action.payload }

    case CHOOSE_LEADER_START:
      return { ...state, settingLeader: true }
    case CHOOSE_LEADER_SUCCESS:
      return { ...state, settingLeader: false }
    case CHOOSE_LEADER_FAILURE:
      return { ...state, settingLeader: false, error: action.payload }

    case USER_REQUEST_LEADER_START:
      return { ...state, settingLeader: true }
    case USER_REQUEST_LEADER_SUCCESS:
      return { ...state, settingLeader: false }
    case USER_REQUEST_LEADER_FAILURE:
      return { ...state, settingLeader: false, error: action.payload }

    case LEADER_REQUEST_ADMIN_START:
      return { ...state, settingLeader: true }
    case LEADER_REQUEST_ADMIN_SUCCESS:
      return { ...state, settingLeader: false }
    case LEADER_REQUEST_ADMIN_FAILURE:
      return { ...state, settingLeader: false, error: action.payload }

    case GET_REQUESTS_START:
      return { ...state, loadingRequests: true }
    case GET_REQUESTS_SUCCESS:
      return { ...state, loadingRequests: false, requests: action.payload }
    case GET_REQUESTS_FAILURE:
      return { ...state, loadingRequests: false, requestsError: action.payload }

    case REPLY_USER_REQUEST_START:
      return { ...state }
    case REPLY_USER_REQUEST_SUCCESS:
      return { ...state }
    case REPLY_USER_REQUEST_FAILURE:
      return { ...state }
    
    case SAVE_LEADER_START:
      return { ...state, loading: true }
    case SAVE_LEADER_SUCCESS:
      return { ...state, loading: false }
    case SAVE_LEADER_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default: return { ...state };
  }
}
