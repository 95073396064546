import {
  all,
  put,
  call,
  fork,
  take,
  cancel,
  select,
  takeLatest,
} from 'redux-saga/effects';
import rsf, { db } from '../rsf';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  syncUserSuccess,
  transformUser as transformUserAction,
  transformUserSuccess,
  transformUserFailure,
  signupSuccess,
  signupFailure,
  requestEmailVerificationSuccess,
  requestEmailVerificationFailure,
  forgotPasswordFailure,
  forgotPasswordSuccess,
} from '../actions/auth.action';
import {
  LOGOUT_START,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_START,
  REQUEST_EMAIL_VERIFICATION_START,
  SYNC_USER_SUCCESS,
  FORGOT_PASSWORD_START,
} from '../actionTypes';
import { history } from '../../History';
import { showToast } from 'redux/actions/toast.action';
import { initNotification } from 'services/notifications';

function* transformUser({ payload: user = {} }) {
  try {
    yield put(transformUserAction());
    const defaultArrayKeys = ['church', 'leader', 'state', 'country', 'subscription'];
    for (const key of defaultArrayKeys) {
      if (!user[key] || !user[key].get) continue;
      const newValue = yield call(rsf.firestore.getDocument, user[key]);
      user[key] = newValue.data();
    }
    yield put(transformUserSuccess(user));
  } catch(error) {
    yield put(transformUserFailure(error));
  }
}

function* login({ payload }) {
  const { email, password } = payload;
  try {
    yield call(rsf.auth.signInWithEmailAndPassword, email, password);
  } catch(error) {
    yield put(loginFailure(error));
    yield put(showToast({ type: 'error', message: error.message }));
  }
}

function* forgotPassword({ payload }) {
  const { email } = payload;
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email);
    yield put(forgotPasswordSuccess());
    yield put(showToast({ type: 'success', message: `The email has been sent to ${email}` }));
    yield call(history.push, '/auth/login');
  } catch(error) {
    yield put(forgotPasswordFailure(error));
    yield put(showToast({ type: 'error', message: error.message }));
  }
}

function* signup({ payload }) {
  try {
    let { email, password, firstName, lastName, leader } = payload;
    email = email.toLowerCase();
    const role = leader ? 'leader' : 'user';
    const { user } = yield call(rsf.auth.createUserWithEmailAndPassword, email, password);
    yield call(
      rsf.firestore.setDocument,
      `users/${user.uid}`,
      {
        id: user.uid,
        active: false,
        verified: false,
        subscribed: false,
        signupStep: 'step 1',
        firstName,
        lastName,
        role,
        email,
      },
    );

    // Create 1 month free START
    const db_user = db.doc(`users/${user.uid}`);
    const expire_date = new Date();
    expire_date.setDate(expire_date.getDate() + 30);
    // Create new sub
    let newSub = {
      data: {
      code: 'first-month-free',
      duration: 30
      },
      status: 'active',
      type: 'first-month-free',
      user: db_user,
      createdAt: new Date(),
      subscriptionPeriodEnd: new Date(expire_date),
      subscriptionPeriodStart: new Date()
    };
    const new_sub_ref = yield call(rsf.firestore.addDocument, '/subscriptions/', newSub);

    // Update user with new sub
    let userDataToUpdate = {
      validated: false,
      subscribed: true,
      subscription:  new_sub_ref
    };
    yield call(rsf.firestore.updateDocument, `users/${user.uid}`, {
      userDataToUpdate
    });
    // Create 1 month free END

    yield put(signupSuccess());
  } catch(error) {
    yield put(signupFailure(error));
    yield put(showToast({ type: 'error', message: error.message }));
  }
}

function* logout() {
  try {
    yield call(rsf.auth.signOut);
    yield put(logoutSuccess());
  } catch(err) {
    yield put(logoutFailure(err));
    yield put(showToast({ type: 'error', message: err.message }));
  }
}

function* loginStatusWatcher() {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      yield call(initNotification, user.uid)
      yield put(loginSuccess(user));
    } else {
      yield put(logoutSuccess());
    }
  }
}

function* userWatcher() {
  let task;
  while (true) {
    const { payload: user } = yield take(LOGIN_SUCCESS);
    const signingUp = yield select(({ Auth }) => Auth.signingUp);

    if (signingUp) {
      yield take(SIGNUP_SUCCESS);
    }

    task = yield fork(
      rsf.firestore.syncDocument,
      `users/${user.uid}`,
      {
        successActionCreator: syncUserSuccess,
      },
    );

    // Wait for the logout action, then stop sync
    yield take(LOGOUT_SUCCESS);
    if (task) yield cancel(task);
  }
}

function* requestEmailVerification() {
  try {
    yield call(rsf.auth.sendEmailVerification, {
      url: 'https://app.dtheartandsoul.com/',
      iOS: {
        bundleId: 'org.reactjs.native.example.DTHS',
      },
      android: {
        packageName: 'com.dhts',
        installApp: true,
        minimumVersion: '12',
      },
      handleCodeInApp: false,
    });
    yield put(requestEmailVerificationSuccess());
  } catch(err) {
    yield put(requestEmailVerificationFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(LOGOUT_START, logout),
    takeLatest(SYNC_USER_SUCCESS, transformUser),
    takeLatest(LOGIN_START, login),
    takeLatest(FORGOT_PASSWORD_START, forgotPassword),
    takeLatest(SIGNUP_START, signup),
    takeLatest([REQUEST_EMAIL_VERIFICATION_START, SIGNUP_SUCCESS], requestEmailVerification),
    fork(loginStatusWatcher),
    fork(userWatcher),
  ]);
}
