import {
  SYNC_NOTIFICATION_START,
  SYNC_NOTIFICATION_FAILURE,
  SYNC_NOTIFICATION_SUCCESS,
  SYNC_NOTIFICATION_TRANSFORM,
  READ_NOTIFICATIONS_START,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,

} from '../actionTypes';

const initial_state = {
  notifications: [],
  error: '',
  unseen: 0,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case SYNC_NOTIFICATION_START:
      return { ...state };
    case SYNC_NOTIFICATION_TRANSFORM:
      return { ...state, notifications: action.payload };
    case SYNC_NOTIFICATION_SUCCESS:
      return { ...state, notifications: action.payload.notifications, unseen: action.payload.unseen };
    case SYNC_NOTIFICATION_FAILURE:
      return { ...state, error: action.payload };

    case READ_NOTIFICATIONS_START:
      return { ...state };
    case READ_NOTIFICATIONS_SUCCESS:
      return { ...state };
    case READ_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
}
