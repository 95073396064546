import {
  GET_STATES_START,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
} from '../actionTypes';

const initial_state = {
  all: [],
  loading: true,
  states: [],
  loadingStates: true,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_COUNTRIES_START:
      return { ...state, loading: true }
    case GET_COUNTRIES_SUCCESS:
      return { ...state, loading: false, all: action.payload }
    case GET_COUNTRIES_FAILURE:
      return { ...state, loading: false }

    case GET_STATES_START:
      return { ...state, loadingStates: true }
    case GET_STATES_SUCCESS:
      return { ...state, loadingStates: false, states: action.payload }
    case GET_STATES_FAILURE:
      return { ...state, loadingStates: false }

    default: return { ...state };
  }
}
