import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
  {
    id: 'discover',
    title: 'Discover',
    type: 'item',
    icon: <Icon.Search size={20} />,
    permissions: ['user'],
    navLink: '/discover',
  },
  {
    id: 'chat',
    title: 'Connect',
    type: 'item',
    icon: <Icon.MessageSquare size={20} />,
    permissions: ['user', 'leader'],
    navLink: '/chat',
  },
  {
    id: 'profile',
    title: 'Profile',
    type: 'item',
    icon: <Icon.User size={20} />,
    permissions: ['user', 'leader'],
    navLink: '/profile',
  },
  {
    id: 'verifications',
    title: 'Verifications',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['leader'],
    navLink: '/verification',
  },
];

export default navigationConfig;
