import {
  SEND_CONTACT_FORM_START,
  SEND_CONTACT_FORM_FAILURE,
  SEND_CONTACT_FORM_SUCCESS,
} from '../actionTypes';

export function sendContact(contactForm) {
  return { type: SEND_CONTACT_FORM_START, contactForm };
}

export function sendContactSuccess() {
  return { type: SEND_CONTACT_FORM_SUCCESS };
}

export function sendContactFailure(payload) {
  return { type: SEND_CONTACT_FORM_FAILURE, payload };
}
