export const changeMode = mode => {
  return { type: "CHANGE_MODE", mode };
}

export const collapseSidebar = value => {
  return { type: "COLLAPSE_SIDEBAR", value };
}

export const changeNavbarColor = color => {
  return { type: "CHANGE_NAVBAR_COLOR", color };
}

export const changeNavbarType = style => {
  return { type: "CHANGE_NAVBAR_TYPE", style };
}

export const changeFooterType = style => {
  return { type: "CHANGE_FOOTER_TYPE", style };
}

export const changeMenuColor = style => {
  return { type: "CHANGE_MENU_COLOR", style };
}

export const hideScrollToTop = value => {
  return { type: "HIDE_SCROLL_TO_TOP", value };
}
