import firebase from 'firebase/app';
import { messaging, db } from '../redux/rsf';

export const initNotification = async userID => {
  if (!messaging) return;

  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    console.log('Unable to get permission to notify.');
    return;
  }

  console.log('Notification permission granted.');

  try {
    const newPushToken = await messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
    if (newPushToken) {
      messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
      });

      await db.collection('users').doc(userID).update({
        pushToken: firebase.firestore.FieldValue.arrayUnion(newPushToken),
      });
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
      // updateUIForPushPermissionRequired();
      // setTokenSentToServer(false);
    }

  } catch(err) {
    console.log('An error occurred while retrieving token. ', err);
    // showToken('Error retrieving Instance ID token. ', "err");
    // setTokenSentToServer(false);
  }
};
