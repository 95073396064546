import {
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_START,
  GET_QUESTIONS_SUCCESS,
} from "../actionTypes";

export const getQuestions = () => ({
  type: GET_QUESTIONS_START,
});

export const getQuestionsSuccess = (questions) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions,
});

export const getQuestionsFailure = (err) => ({
  type: GET_QUESTIONS_FAILURE,
  payload: err
});
