import {
  GET_CHURCHES_START,
  GET_CHURCHES_SUCCESS,
  GET_CHURCHES_FAILURE,
} from '../actionTypes';

const initial_state = {
  all: [],
  loading: true,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_CHURCHES_START:
      return { ...state, loading: true }
    case GET_CHURCHES_SUCCESS:
      return { ...state, loading: false, all: action.payload }
    case GET_CHURCHES_FAILURE:
      return { ...state, loading: false }

    default: return { ...state };
  }
}
