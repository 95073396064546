import {
  GET_LEADERS_FROM_CHURCH_START,
  GET_LEADERS_FROM_CHURCH_SUCCESS,
  GET_LEADERS_FROM_CHURCH_FAILURE,
  CHOOSE_LEADER_START,
  CHOOSE_LEADER_SUCCESS,
  CHOOSE_LEADER_FAILURE,
  USER_REQUEST_LEADER_START,
  USER_REQUEST_LEADER_SUCCESS,
  USER_REQUEST_LEADER_FAILURE,
  LEADER_REQUEST_ADMIN_START,
  LEADER_REQUEST_ADMIN_SUCCESS,
  LEADER_REQUEST_ADMIN_FAILURE,
  GET_REQUESTS_START,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAILURE,
  REPLY_USER_REQUEST_START,
  REPLY_USER_REQUEST_SUCCESS,
  REPLY_USER_REQUEST_FAILURE,
  GET_LEADERS_START,
  GET_LEADERS_SUCCESS,
  GET_LEADERS_FAILURE,
  GET_LEADER_START,
  GET_LEADER_SUCCESS,
  GET_LEADER_FAILURE,
  SET_CURRENT_LEADER,
  SAVE_LEADER_START,
  SAVE_LEADER_SUCCESS,
  SAVE_LEADER_FAILURE,
} from '../actionTypes'

export const getLeadersFromChurch = (churchID) => ({
  type: GET_LEADERS_FROM_CHURCH_START,
  payload: { churchID },
});

export const getLeadersFromChurchSuccess = (leaders) => ({
  type: GET_LEADERS_FROM_CHURCH_SUCCESS,
  payload: leaders,
});

export const getLeadersFromChurchFailure = (err) => ({
  type: GET_LEADERS_FROM_CHURCH_FAILURE,
  payload: err
});

export const getLeaders = () => ({
  type: GET_LEADERS_START,
});

export const getLeadersSuccess = (leaders) => ({
  type: GET_LEADERS_SUCCESS,
  payload: leaders,
});

export const getLeadersFailure = (err) => ({
  type: GET_LEADERS_FAILURE,
  payload: err,
});

export const getLeader = (payload) => ({
  type: GET_LEADER_START,
  payload,
});

export const getLeaderSuccess = (leader) => ({
  type: GET_LEADER_SUCCESS,
  payload: leader,
});

export const getLeaderFailure = (err) => ({
  type: GET_LEADER_FAILURE,
  payload: err,
});

export const setCurrentLeader = (leader) => ({
  type: SET_CURRENT_LEADER,
  payload: leader,
});

export const chooseLeader = (payload) => ({
  type: CHOOSE_LEADER_START,
  payload,
});

export const chooseLeaderSuccess = () => ({
  type: CHOOSE_LEADER_SUCCESS,
});

export const chooseLeaderFailure = (err) => ({
  type: CHOOSE_LEADER_FAILURE,
  payload: err
});

export const userRequestLeader = (payload) => ({
  type: USER_REQUEST_LEADER_START,
  payload,
});

export const userRequestLeaderSuccess = () => ({
  type: USER_REQUEST_LEADER_SUCCESS,
});

export const userRequestLeaderFailure = (err) => ({
  type: USER_REQUEST_LEADER_FAILURE,
  payload: err,
})

export const leaderRequestAdmin = (payload) => ({
  type: LEADER_REQUEST_ADMIN_START,
  payload,
});

export const leaderRequestAdminSuccess = () => ({
  type: LEADER_REQUEST_ADMIN_SUCCESS,
});

export const leaderRequestAdminFailure = (err) => ({
  type: LEADER_REQUEST_ADMIN_FAILURE,
  payload: err,
})

export const getRequests = (payload) => ({
  type: GET_REQUESTS_START,
  payload,
});

export const getRequestsSuccess = (requests) => ({
  type: GET_REQUESTS_SUCCESS,
  payload: requests,
});

export const getRequestsFailure = (err) => ({
  type: GET_REQUESTS_FAILURE,
  payload: err,
})

export const replyUserRequest = (payload, onSuccess, toast) => ({
  type: REPLY_USER_REQUEST_START,
  payload,
  onSuccess,
  toast,
});

export const replyUserRequestSuccess = () => ({
  type: REPLY_USER_REQUEST_SUCCESS,
});

export const replyUserRequestFailure = (err) => ({
  type: REPLY_USER_REQUEST_FAILURE,
  payload: err,
})

export const saveLeader = (payload, onSuccess) => ({
  type: SAVE_LEADER_START,
  payload,
  onSuccess,
});

export const saveLeaderSuccess = () => ({
  type: SAVE_LEADER_SUCCESS,
});

export const saveLeaderFailure = (err) => ({
  type: SAVE_LEADER_FAILURE,
  payload: err,
})
