import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_STATES_START,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from '../actionTypes';

export const getCountries = () => ({
  type: GET_COUNTRIES_START
})

export const getCountriesSuccess = (countries) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})

export const getCountriesFailure = (err) => ({
  type: GET_COUNTRIES_FAILURE,
  payload: err,
})

export const getStates = (countryID) => ({
  type: GET_STATES_START,
  payload: { countryID },
})

export const getStatesSuccess = (states) => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFailure = (err) => ({
  type: GET_STATES_FAILURE,
  payload: err,
})
