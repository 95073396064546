import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import Axios from "axios";
import { getGeoLocationFailure, getGeoLocationSuccess } from "redux/actions/geo.action";
import { GET_GEOLOCATION_START } from "redux/actionTypes";

function* getGeoInfo() {
  const url = `https://ipapi.co/json`;
  try {
    const result = yield call(Axios.get, url);
    yield put(getGeoLocationSuccess({
      countryCode: result.data.country,
      countryName: result.data.country_name,
    }));
  } catch (err) {
    yield put(getGeoLocationFailure(err))
  }
};

export default function* geoSaga() {
  yield all([
    takeEvery(GET_GEOLOCATION_START, getGeoInfo),
  ]);
}
