import {
  all,
  put,
  fork,
  call,
  take,
  cancel,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  READ_NOTIFICATIONS_START,
  SYNC_NOTIFICATION_TRANSFORM,
} from '../actionTypes';
import {
  readNotificationsFailure,
  readNotificationsSuccess,
  syncNotification,
  syncNotificationFailure,
  syncNotificationSuccess,
  syncNotificationTransform,
} from '../actions/notification.action';
import rsf, { db } from '../rsf';

function getUnseenNotifications(notifications) {
  return notifications.filter((notification) => !notification.viewed).length;
}

function* transformNotification({ payload }) {
  try {
    const sendersRef = payload.map((notification) => (
      call(rsf.firestore.getDocument, notification.sender)
    ));
    const senders = yield all(sendersRef);
    const notifications = payload.map((notification, i) => ({
      ...notification,
      sender: senders[i].data(),
    }));
    const unseen = getUnseenNotifications(notifications);

    yield put(syncNotificationSuccess({ notifications, unseen }));
  } catch(error) {
    yield put(syncNotificationFailure(error));
  }
}

function* readNotifications() {
  try {
    const user = yield select(({ Auth }) => Auth.user);

    const notificationsRef = db
      .collection(`users/${user.id}/notifications`)
      .where('viewed', '==', false);

    const notificationsQuery = yield call(rsf.firestore.getCollection, notificationsRef);
    notificationsQuery.forEach((notification) => {
      notification.ref.update({ viewed: true });
    });

    yield put(readNotificationsSuccess());
  } catch(err) {
    yield put(readNotificationsFailure(err));
  }
}

function* notificationWatcher() {
  let task;
  while (true) {
    const { payload: user } = yield take(LOGIN_SUCCESS);
    yield put(syncNotification());
    const collectionRef = db
    .collection(`users/${user.uid}/notifications`)
    .orderBy('created', 'desc');

    task = yield fork(
      rsf.firestore.syncCollection,
      collectionRef,
      {
        successActionCreator: syncNotificationTransform,
        failureActionCreator: syncNotificationFailure,
        transform: (results) =>
          results.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      },
    );

    // Wait for the logout action, then stop sync
    yield take(LOGOUT_SUCCESS);
    if (task) yield cancel(task);
  }
}

export default function* notificationSaga() {
  yield all([
    takeLatest(SYNC_NOTIFICATION_TRANSFORM, transformNotification),
    takeLatest(READ_NOTIFICATIONS_START, readNotifications),
    fork(notificationWatcher),
  ]);
}
