import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
} from '../actionTypes';

const initial_state = {
  files: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case UPLOAD_FILE_START:
      return { ...state, files: { ...state.files, [action.file.id]: { ...action.file, loading: true } } };
    case UPLOAD_FILE_SUCCESS:
      return { ...state, files: { ...state.files, [action.file.id]: { ...action.file, loading: false } } };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        files: { ...state.files, [action.file.id]: { ...action.file, loading: false, err: action.err } },
      };

    default: return { ...state };
  }
}
