import {
  GET_CHURCHES_START,
  GET_CHURCHES_SUCCESS,
  GET_CHURCHES_FAILURE,
} from '../actionTypes';

export const getChurches = (country, state) => ({
  type: GET_CHURCHES_START,
  payload: { country, state },
})

export const getChurchesSuccess = (churches) => ({
  type: GET_CHURCHES_SUCCESS,
  payload: churches,
})

export const getChurchesFailure = (err) => ({
  type: GET_CHURCHES_FAILURE,
  payload: err,
})
