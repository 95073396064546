import {
  Media,
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import moment from 'moment';
import * as Icon from 'react-feather';
import { history } from '../../../History';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { logout } from '../../../redux/actions/auth.action';
import { readNotifications } from '../../../redux/actions/notification.action';
import { setCurrentChannel } from '../../../redux/actions/chat.action';

const getChannelByUser = (userId, channels) => {
  if (!Object.entries(channels).length) return;
  return Object.entries(channels).find(([channelID, channel]) => channel.friend?.id === userId);
};

const UserDropdown = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const goToContact = () => {
    history.push('/contact');
  };

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={goToContact}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Contact Us</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="#" onClick={handleLogout}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const Notification = ({ notification }) => {
  let title, icon, message;
  const dispatch = useDispatch();
  const channels = useSelector(({ Chat }) => Chat.channels);
  const firstName = notification.sender?.firstName || '';
  const senderId = notification.sender?.id || '';
  const date = notification.created.toDate();
  const dateAgo = moment(date).fromNow();
  const channel = getChannelByUser(senderId, channels);

  if (notification.type === 'message') {
    title = `Unseen message from ${firstName}.`;
    icon = <Icon.PlusSquare className="font-medium-5 primary" size={21} />;
    message = notification.content;
  } else if (notification.type === 'like') {
    const resource = notification.content.split(' ')[0];
    title = `${firstName} liked your ${resource}`;
    icon = <Icon.Heart className="font-medium-5 primary" size={21} />;
  } else if (notification.type === 'request') {
    title = `${firstName} applied to join DTHS`;
    icon = <Icon.Heart className="font-medium-5 primary" size={21} />;
  }

  const unseen = !notification.viewed && <div className="unseen"></div>;

  const handleClickNotification = () => {
    if (channel) dispatch(setCurrentChannel(channel[0]));
    if (notification.type === 'request') {
      return history.push('/verification');
    }
    history.push('/chat');
  };

  return (
    <div
      onClick={handleClickNotification}
      className="d-flex justify-content-between align-items-center position-relative"
    >
      {unseen}
      <Media className="d-flex align-items-start">
        <Media left href="#">
          {icon}
        </Media>
        <Media body>
          <Media heading className="primary media-heading" tag="h6">
            {title}
          </Media>
          <p className="notification-text">
            {message}
          </p>
        </Media>
        <small>
          <time
            className="media-meta"
            dateTime="2015-06-11T18:29:20+08:00"
          >
            {dateAgo}
          </time>
        </small>
      </Media>
    </div>
  );
};

function NavbarUser(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ Auth }) => Auth.user);
  const notifications = useSelector(({ Notification }) => Notification.notifications);
  const unseenProp = useSelector(({ Notification }) => Notification.unseen);
  const [unseen, setUnseen] = useState(unseenProp);
  const [clicked, setClicked] = useState(false);
  const notificationElements = notifications.map((notification, i) => (
    <Notification notification={notification} key={i} />
  ));

  useEffect(() => {
    if (unseenProp !== unseen && !clicked) {
      setUnseen(unseenProp);
    }
    setClicked(false);
  }, [unseenProp, unseen]);

  const badge = !!unseen && (
    <Badge pill color="primary" className="badge-up">
      {unseen >= 20 ? '20+' : unseen}
    </Badge>
  );

  const header = !!unseen && (
    <h3 className="text-white">{unseen >= 20 ? '20+' : unseen} New</h3>
  );

  const handleViewNotifications = () => {
    setUnseen(0);
    setClicked(true);
    dispatch(readNotifications());
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown
        tag="li"
        className="dropdown-notification nav-item"
      >
        <DropdownToggle onClick={handleViewNotifications} tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} />
          {badge}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              {header}
              <span className="notification-title">App Notifications</span>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{ wheelPropagation: false }}
          >
            {notificationElements}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600 mb-0">
              {user.firstName} {user.lastName}
            </span>
            {/* <span className="user-status">Available</span> */}
          </div>
          <span data-tour="user">
            <img
              src={user.profilePicture.fullURL}
              className="round"
              height="40"
              width="40"
              alt="avatar"
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  );
}

export default NavbarUser;
