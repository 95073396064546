import { all, takeEvery, call, select, put } from "redux-saga/effects";
import {
  SUBSCRIPTION_REQUEST_START,
  CHANGE_PAYMENT_METHOD_START,
  PROMO_CODE_SUBSCRIBE_START,
} from "redux/actionTypes";
import axios from 'axios';
import { subscribeFailure, subscribeSuccess } from "redux/actions/subscription.action";
import { showToast } from "redux/actions/toast.action";

function* subscribe({ payload }) {
  const url = `${process.env.REACT_APP_DTHS_FUNCTIONS_URL}/api/stripe/subscribe`;
  const authToken = yield select(({ Auth }) => Auth.authInfo.stsTokenManager.accessToken)
  const email = yield select(({ Auth }) => Auth.user.email)
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    }
  }
  const body = {
    email,
    paymentMethod: payload.paymentMethod,
    priceId: payload.priceId,
  }
  try {
    const result = yield call(axios.post, url, body, options)
    yield put(subscribeSuccess(result));
    yield put(showToast({ type: 'success', message: 'Payment successful!' }))
  } catch (err) {
    yield put(showToast({ type: 'error', message: err.message }))
    yield put(subscribeFailure(err))
  }
}

function* changePaymentMethod({ payload }) {
  const url = `${process.env.REACT_APP_DTHS_FUNCTIONS_URL}/api/stripe/subscription/payment-method`;
  const authToken = yield select(({ Auth }) => Auth.authInfo.stsTokenManager.accessToken)
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    }
  }
  const body = {
    type: 'card',
    paymentMethod: payload.paymentMethod
  }
  try {
    const result = yield call(axios.post, url, body, options)
    yield put(subscribeSuccess(result));
    yield put(showToast({ type: 'success', message: 'Change payment method successful!' }))
  } catch (err) {
    yield put(showToast({ type: 'error', message: err.message }))
    yield put(subscribeFailure(err))
  }
}

function* promoCodeSubscribe({ payload }) {
  const url = `${process.env.REACT_APP_DTHS_FUNCTIONS_URL}/api/promocode/subscribe`;
  const authToken = yield select(({ Auth }) => Auth.authInfo.stsTokenManager.accessToken)
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    }
  }
  try {
    const result = yield call(axios.post, url, { promoCode: payload.promoCode }, options)
    yield put(subscribeSuccess(result));
    yield put(showToast({ type: 'success', message: 'Subscribed using promo code' }))
  } catch (err) {
    yield put(showToast({ type: 'error', message: err.response?.data?.error || err.message }))
    yield put(subscribeFailure(err))
  }
}

export default function* subscriptionSaga() {
  yield all([
    takeEvery(SUBSCRIPTION_REQUEST_START, subscribe),
    takeEvery(CHANGE_PAYMENT_METHOD_START, changePaymentMethod),
    takeEvery(PROMO_CODE_SUBSCRIBE_START, promoCodeSubscribe),
  ]);
}
