import { all, call, put, takeEvery } from 'redux-saga/effects';
import { uploadFileFailure, uploadFileSuccess } from 'redux/actions/storage.action';
import { UPLOAD_FILE_START } from 'redux/actionTypes';
import rsf from 'redux/rsf';

function* uploadFile(action) {
  const upload = rsf.storage.uploadFile(action.ref, action.file.fullURL);
  // const channel = eventChannel(emit => task.on('state_changed', emit));

  // yield takeEvery(channel, ...);
  try {
    const { state, ref } = yield upload;

    if (state === 'success') {
      const fullURL = yield call(rsf.storage.getDownloadURL, ref);
      yield put(uploadFileSuccess({ ...action.file, fullURL, url: ref.fullPath, state }));
    } else {
      yield put(uploadFileFailure(new Error('File could not be uploaded.'), { ...action.file, state }));
    }
  } catch (error) {
    yield put(uploadFileFailure(error, { ...action.file, state: 'error' }));
  }
  URL.revokeObjectURL(action.file.fullURL)
}

export default function* storageSaga() {
  yield all([
    takeEvery(UPLOAD_FILE_START, uploadFile),
  ]);
}
