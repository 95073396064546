import {
  DISCOVER_START,
  DISCOVER_SUCCESS,
  DISCOVER_FAILURE,
  NEXT_USER_START,
  NEXT_USER_SUCCESS,
  NEXT_USER_FAILURE,
  FIRST_USER_START,
  FIRST_USER_SUCCESS,
  FIRST_USER_FAILURE,
  LIKE_START,
  LIKE_SUCCESS,
  LIKE_FAILURE,
  DISLIKE_START,
  DISLIKE_SUCCESS,
  DISLIKE_FAILURE,
  RESTART_DISCOVERY,
} from 'redux/actionTypes';

export const discover = () => ({
  type: DISCOVER_START,
});

export const discoverSuccess = (payload) => ({
  type: DISCOVER_SUCCESS,
  payload,
});

export const discoverFailure = (err) => ({
  type: DISCOVER_FAILURE,
  payload: err,
});

export const nextUser = () => ({
  type: NEXT_USER_START,
});

export const nextUserSuccess = (payload) => ({
  type: NEXT_USER_SUCCESS,
  payload,
});

export const nextUserFailure = (err) => ({
  type: NEXT_USER_FAILURE,
  payload: err,
});

export const firstUser = (payload) => ({
  type: FIRST_USER_START,
  payload,
});

export const firstUserSuccess = (payload) => ({
  type: FIRST_USER_SUCCESS,
  payload,
});

export const firstUserFailure = (err) => ({
  type: FIRST_USER_FAILURE,
  payload: err,
});

export const like = (payload) => ({
  type: LIKE_START,
  payload,
});

export const likeSuccess = (payload) => ({
  type: LIKE_SUCCESS,
  payload,
});

export const likeFailure = (err) => ({
  type: LIKE_FAILURE,
  payload: err,
});

export const dislike = (payload) => ({
  type: DISLIKE_START,
  payload,
});

export const dislikeSuccess = (payload) => ({
  type: DISLIKE_SUCCESS,
  payload,
});

export const dislikeFailure = (err) => ({
  type: DISLIKE_FAILURE,
  payload: err,
});

export const restartDiscovery = () => ({
  type: RESTART_DISCOVERY,
})